



// function saveOrder() {
//     var data = $("#drag_box .drag_item").map(function () {
//         return $(this).children().html();
//     }).get();
//     // $("input[name=list1SortOrder]").val(data.join("|"));
// }

// $(function () {
//     setTimeout(function(){
//         $("#drag_box").dragsort({
//             dragSelector: "div",
//             dragBetween: true,
//             dragEnd: saveOrder,
//             placeHolderTemplate: "<div class='placeHolder' style='float: left; width: 33%!important; padding-right:10px; padding-bottom:10px'>" +
//             "<div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 100%!important'>&nbsp;</div>" +
//             "</div>",
//             cursor: "move"
//         });
//     }, 3000)
// });
